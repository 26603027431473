<template>
  <ess-page-content title="Application Logs">
    <v-container class="pa-0">
      <v-row class="mb-5">
        <v-spacer />
        <v-btn :disabled="errors.length === 0"
               color="success"
               large
               depressed
               class="text-right"
               @click="downloadLogs">
          Download Logs
        </v-btn>
      </v-row>

      <ess-empty-message v-if="errors.length === 0"
                         message="No Error Logs"
                         sub-message="No Error Logs available at this time" />

      <v-row>
        <v-expansion-panels v-model="panel"
                            multiple>
          <template v-for="(item, index) in errors">
            <v-expansion-panel :key="index">
              <v-expansion-panel-header class="text-body-2 py-0">
                <v-container>
                  <v-row>
                    <v-col cols="6"
                           class="py-0 ess-font-title">
                      {{ item.context }}
                    </v-col>
                    <v-col cols="6"
                           class="py-0 text-right">
                      {{ item.timestamp }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6"
                           class="py-0">
                      {{ item.error }}
                    </v-col>
                    <v-col cols="3"
                           class="py-0 text-right">
                      {{ item.username }}
                    </v-col>
                    <v-col cols="3"
                           class="py-0 text-right">
                      {{ item.version }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-body-2">
                <v-container>
                  <v-divider />
                  <v-row>
                    <v-col cols="12">
                      <div class="ess-font-text-caption mb-3">
                        Stacktrace:
                      </div>
                      {{ item.stacktrace }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row>
                    <v-col cols="12">
                      <div class="ess-font-text-caption mb-3">
                        Metadata:
                      </div>
                      <pre v-highlightjs><code class="json">{{ JSON.parse(item.metadata) }}</code></pre>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row>
                    <v-col cols="12">
                      <div class="ess-font-text-caption mb-3">
                        Client Details:
                      </div>
                      <pre v-highlightjs><code class="json">{{ JSON.parse(item.clientDetails) }}</code></pre>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-row>

      <v-row class="mt-5">
        <v-spacer />
        <v-btn color="primary"
               large
               outlined
               depressed
               class="text-right mr-5"
               @click="clearLogs">
          Clear
        </v-btn>
        <input id="fileUpload"
               ref="fileUpload"
               type="file"
               hidden
               @change="importLogs">
        <v-btn :loading="$wait.is('logsLoading')"
               color="primary"
               large
               outlined
               depressed
               class="text-right"
               @click="$refs.fileUpload.click()">
          Import Logs
        </v-btn>
      </v-row>
    </v-container>
  </ess-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  components: {},

  data() {
    return {
      panel: [],
    };
  },

  computed: {
    ...mapGetters({
      errors: 'logs/errors',
    }),

    numberOfPages() {
      return Math.ceil(this.errors.length / this.itemsPerPage);
    },
  },

  methods: {
    ...mapActions('logs', ['importLogs']),

    ...mapWaitingActions('logs', {
      importLogs: 'logsLoading',
    }),

    downloadLogs() {
      const errorsFile = _cloneDeep(
        this.errors.map((x) => ({
          id: x.id,
          username: x.username,
          type: x.type,
          error: x.error,
          stacktrace: x.stacktrace,
          timestamp: x.timestamp,
          version: x.version,
          context: x.context,
          metadata: x.metadata,
          clientDetails: x.clientDetails,
          ipAddress: x.ipAddress,
        })),
      );
      const blob = new Blob([JSON.stringify(errorsFile, undefined, 2)], { type: 'text:json' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `error-logs-${moment().format('YYYYMMDD_HHmmss')}.json`;
      link.click();
    },

    clearLogs() {
      this.$store.commit('logs/errors', []);
    },
  },
};
</script>
